@import '../../styles/colors';
@import '../../styles/fonts';

nav {
  background-color: $offWhite;
}

.navbar-toggler {
  border: none;
  font-size: 0.875rem;
  margin-top: 0.35rem;
}

.nav-link {
  font-weight: 500;
  text-decoration: underline 0.2em;
  text-decoration-color: $offWhite;
  text-underline-offset: 0.2em;
  transition: color 500ms, text-decoration-color 500ms;
}

.nav-link:hover {
  text-decoration-color: $fernGreen;
  transition: color 500ms, text-decoration-color 500ms;
}

#navbarScrollingDropdown::after {
  content: none;
}

.dropdown-menu {
  min-width: fit-content;
  border: none;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.dropdown-menu:hover .social-icon {
  color: $offPlatinum;
  transition: color 500ms;
}

.dropdown-item:hover {
  background-color: inherit;
}

.dropdown-item:hover .social-icon {
  color: $fernGreen;
  transition: color 500ms;
}

.dropdown-item:active {
  background-color: inherit;
}

.letter-icon {
  font-family: $iconFontFamily;
  top: 1.75rem;
}

.letter {
  transition: color 500ms;
}

.letter:hover {
  color: $fernGreen;
  transition: color 500ms;
}
