@import 'styles/colors';
@import 'styles/fonts';

body {
  background-color: $offWhite;
  padding-top: 3.5rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $bodyFontFamily;
  font-weight: $bodyFontWeight;
  color: $outerSpace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headingFontFamily;
  font-weight: $headingFontWeight;
  color: $fernGreen;
}

code {
  font-family: $codeFontFamily;
  font-weight: $codeFontWeight;
}

li::marker {
  color: $fernGreen;
}

::selection {
  background: $platinum;
  color: $outerSpace;
}

:focus {
  outline-color: $fernGreen;
}

/* Links */

.link-off-white {
  color: $outerSpace;
  font-weight: bold;
  text-decoration: underline 0.19em;
  text-decoration-color: $offWhite;
  text-underline-offset: 0.175em;
  transition: color 500ms, text-decoration-color 500ms;
}

.link-off-white:hover {
  color: $outerSpace;
  text-decoration-color: $fernGreen;
  transition: color 500ms, text-decoration-color 500ms;
}

.link-white {
  color: $outerSpace;
  font-weight: bold;
  text-decoration: underline 0.19em;
  text-decoration-color: $white;
  text-underline-offset: 0.175em;
  transition: color 500ms, text-decoration-color 500ms;
}

.link-white:hover {
  color: $outerSpace;
  text-decoration-color: $fernGreen;
  transition: color 500ms, text-decoration-color 500ms;
}

.link-card {
  color: $fernGreen;
  font-weight: bold;
  text-decoration: underline 0.19em;
  text-decoration-color: $white;
  text-underline-offset: 0.175em;
  transition: color 500ms, text-decoration-color 500ms;
}

.link-card:hover {
  color: $fernGreen;
  text-decoration-color: $fernGreen;
  transition: color 500ms, text-decoration-color 500ms;
}

/* Images and Cards */

.rounded-corners {
  border-radius: 1rem;
}

.border-purple {
  border: 0.5rem solid $fernGreen;
}

.mono {
  filter: grayscale(100%);
}
