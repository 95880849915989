@import '../../styles/colors';

.project-card {
  height: 25rem;
  max-height: 25rem;
  max-width: 20rem;
  min-height: 25rem;
  min-width: 20rem;
  width: 20rem;
  border: none;
  background-color: $white;
}

.project-icon {
  transition: color 500ms;
}

.project-icon:hover {
  color: $fernGreen;
  transition: color 500ms;
}

.card-img {
  min-height: 11.25rem;
  max-height: 11.25rem;
  min-width: 20rem;
  max-width: 20rem;
  object-fit: cover;
  object-position: 100% 0%;
}

.project-title {
  font-size: 1.09rem;
}
