@import '../../styles/animations';
@import '../../styles/colors';

.skill-list-enter {
  @include fadeIn;
}

.skill-list-enter-active {
  @include fadeInActive;
}

.skill-section {
  color: $raisinBlack;
}

.skill-section-list li::marker {
  font-size: 0.75em;
}
