@mixin fadeIn($translateX: 0px, $translateY: 100px) {
  opacity: 0;
  transform: translate($translateX, $translateY);
}

@mixin fadeInActive($duration: 1000ms, $delay: 0ms) {
  opacity: 1;
  transform: initial;
  transition: all $duration cubic-bezier(0.33, 1, 0.68, 1);
  transition-delay: $delay;
}
