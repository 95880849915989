@import '../../styles/animations';

@media only screen and (min-width: 75rem) {
  .fs-title-container {
    height: calc(100vh - 6.5rem);
  }
}

.fs-subtitle-appear {
  @include fadeIn;
}

.fs-subtitle-appear-active {
  @include fadeInActive(1000ms, 3000ms);
}
