@import '../../styles/colors';

a {
  text-decoration: none;
}

a h2 {
  transition: color 500ms;
  scroll-margin-top: 5rem;
  display: inline-block;
}

a:hover h2 {
  color: $outerSpace;
  transition: color 500ms;
}
