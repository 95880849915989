@import '../../styles/colors';

.blog-post-body {
  line-height: 200%;
  text-align: justify;
}

.blog-post-anchor h2 {
  transition: color 500ms;
  scroll-margin-top: 5rem;
  display: inline-block;
}

.blog-post-anchor:hover h2 {
  color: $outerSpace;
  transition: color 500ms;
}
