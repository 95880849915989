@import '../../styles/colors';

.album-card {
  max-height: 23rem;
  max-width: 16rem;
  min-height: 23rem;
  min-width: 16rem;
  border: none;
  background-color: $offWhite;
}
