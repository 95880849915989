@import '../../styles/colors';

.social-icon {
  color: $outerSpace;
}

.row:hover .social-icon {
  color: $offPlatinum;
  transition: color 500ms;
}

.row .social-icon:hover {
  color: $fernGreen;
  transition: color 500ms;
}
