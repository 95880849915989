@import '../../styles/colors';
@import '../../styles/animations';

.description-appear {
  @include fadeIn;
}

.description-appear-active {
  @include fadeInActive;
}

/* Timeline holder */
ul.timeline {
  display: inline-block;
  list-style-type: none;
  position: relative;
  padding-left: 1.5rem;
}

/* Timeline vertical line */
ul.timeline:before {
  content: ' ';
  background: $white;
  display: inline-block;
  position: absolute;
  left: 1rem;
  width: 0.25rem;
  height: 100%;
  z-index: 0;
  border-radius: 1rem;
}
